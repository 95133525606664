export const aboutObject = {
  src: '/images/LogoBelle.png',
  alt: 'Belle Maison Studio',
  imgStart: false,
  dataAosTxt: 'fade-right',
  dataAosImg: 'fade-left',
  dataAosDuration: '1000',
  dataAosEasing: 'ease-in-out',
  dataAosDelayTxt: '100',
  dataAosDelayImg: '400',
};

export const colabsObject = [
  {
    _id: 1,
    logo: '/images/Logo.png',
    alt: 'Enter colabs name',
    dataAosCard: 'zoom-in-down',
    textColor: true,
    duration: '1000',
    link: 'https://www.facebook.com',
  },
  {
    _id: 2,
    logo: '/images/Logo.png',
    alt: 'Enter colabs name',
    dataAosCard: 'zoom-in-down',
    textColor: false,
    duration: '1400',
    link: 'https://www.facebook.com',
  },
  {
    _id: 3,
    logo: '/images/Logo.png',
    alt: 'Enter colabs name',
    dataAosCard: 'zoom-in-down',
    textColor: true,
    duration: '1600',
    link: 'https://www.facebook.com',
  },
  {
    _id: 4,
    logo: '/images/Logo.png',
    alt: 'Enter colabs name',
    dataAosCard: 'zoom-in-down',
    textColor: false,
    duration: '1800',
    link: 'https://www.facebook.com',
  },
  {
    _id: 5,
    logo: '/images/Logo.png',
    alt: 'Enter colabs name',
    dataAosCard: 'zoom-in-down',
    textColor: true,
    duration: '2000',
    link: 'https://www.facebook.com',
  },
];

export const ImageData = {
  images: [
    {
      _id: 1,
      src: '/images/baie/4.jpg',
      alt: 'Belle Maison Studio',
      descriere: 'Descriere scurta',
    },
    {
      _id: 2,
      src: '/images/baie/6.jpg',
      alt: 'Belle Maison Studio',
      descriere: 'Descriere scurta',
    },
    {
      _id: 3,
      src: '/images/baie/7.jpg',
      alt: 'Belle Maison Studio',
      descriere: 'Descriere scurta',
    },
    {
      _id: 4,
      src: '/images/Dormitor/1-2.jpg',
      alt: 'Belle Maison Studio',
      descriere: 'Descriere scurta',
    },
    {
      _id: 5,
      src: '/images/Dormitor/2.jpeg',
      alt: 'Belle Maison Studio',
      descriere: 'Descriere scurta',
    },
    {
      _id: 6,
      src: '/images/Dormitor/6.jpg',
      alt: 'Belle Maison Studio',
      descriere: 'Descriere scurta',
    },
    {
      _id: 7,
      src: '/images/Dormitor/8.jpg',
      alt: 'Belle Maison Studio',
      descriere: 'Descriere scurta',
    },
    {
      _id: 8,
      src: '/images/Dormitor/7.jpg',
      alt: 'Belle Maison Studio',
      descriere: 'Descriere scurta',
    },
    {
      _id: 9,
      src: '/images/Dormitor/16.jpg',
      alt: 'Belle Maison Studio',
      descriere: 'Descriere scurta',
    },
    {
      _id: 10,
      src: '/images/Dormitor/15.jpg',
      alt: 'Belle Maison Studio',
      descriere: 'Descriere scurta',
    },
    {
      _id: 11,
      src: '/images/Dormitor/14.jpg',
      alt: 'Belle Maison Studio',
      descriere: 'Descriere scurta',
    },
    {
      _id: 12,
      src: '/images/Dormitor/12.jpg',
      alt: 'Belle Maison Studio',
      descriere: 'Descriere scurta',
    },
    {
      _id: 13,
      src: '/images/Dormitor/17.jpg',
      alt: 'Belle Maison Studio',
      descriere: 'Descriere scurta',
    },
    {
      _id: 14,
      src: '/images/Dormitor/18-2.jpg',
      alt: 'Belle Maison Studio',
      descriere: 'Descriere scurta',
    },
    {
      _id: 15,
      src: '/images/Dormitor/19.jpg',
      alt: 'Belle Maison Studio',
      descriere: 'Descriere scurta',
    },
    {
      _id: 16,
      src: '/images/Dormitor/20.jpg',
      alt: 'Belle Maison Studio',
      descriere: 'Descriere scurta',
    },
    {
      _id: 17,
      src: '/images/Dormitor/18.jpg',
      alt: 'Belle Maison Studio',
      descriere: 'Descriere scurta',
    },
    {
      _id: 18,
      src: '/images/Dormitor/21.jpg',
      alt: 'Belle Maison Studio',
      descriere: 'Descriere scurta',
    },
    {
      _id: 19,
      src: '/images/Dormitor/22.jpg',
      alt: 'Belle Maison Studio',
      descriere: 'Descriere scurta',
    },
    {
      _id: 20,
      src: '/images/Dormitor/23.jpg',
      alt: 'Belle Maison Studio',
      descriere: 'Descriere scurta',
    },
    {
      _id: 21,
      src: '/images/Dormitor/24.jpg',
      alt: 'Belle Maison Studio',
      descriere: 'Descriere scurta',
    },
    {
      _id: 22,
      src: '/images/living/1.jpg',
      alt: 'Belle Maison Studio',
      descriere: 'Descriere scurta',
    },
    {
      _id: 23,
      src: '/images/living/2.jpg',
      alt: 'Belle Maison Studio',
      descriere: 'Descriere scurta',
    },
    {
      _id: 24,
      src: '/images/living/3.jpg',
      alt: 'Belle Maison Studio',
      descriere: 'Descriere scurta',
    },
    {
      _id: 25,
      src: '/images/living/5.jpg',
      alt: 'Belle Maison Studio',
      descriere: 'Descriere scurta',
    },
    {
      _id: 26,
      src: '/images/living/7.jpg',
      alt: 'Belle Maison Studio',
      descriere: 'Descriere scurta',
    },
    {
      _id: 27,
      src: '/images/living/8.jpg',
      alt: 'Belle Maison Studio',
      descriere: 'Descriere scurta',
    },
    {
      _id: 28,
      src: '/images/living/9.jpg',
      alt: 'Belle Maison Studio',
      descriere: 'Descriere scurta',
    },
    {
      _id: 29,
      src: '/images/living/11.jpg',
      alt: 'Belle Maison Studio',
      descriere: 'Descriere scurta',
    },
    {
      _id: 30,
      src: '/images/living/13.jpg',
      alt: 'Belle Maison Studio',
      descriere: 'Descriere scurta',
    },
    {
      _id: 31,
      src: '/images/living/14.jpg',
      alt: 'Belle Maison Studio',
      descriere: 'Descriere scurta',
    },
    {
      _id: 32,
      src: '/images/terasa/Balcony-52.jpg',
      alt: 'Belle Maison Studio',
      descriere: 'Descriere scurta',
    },
    {
      _id: 33,
      src: '/images/terasa/Balcony-54.jpg',
      alt: 'Belle Maison Studio',
      descriere: 'Descriere scurta',
    },
  ],
};
